import React from 'react'
import { Reviews, Title} from '../../components';
import {Header, Footer} from '../../containers/';
import {data} from '../../constants'
import ModalImage from "react-modal-image";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const BrandDesigning = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Branding Work including - Corporate | Illustrations | Flyer | Magazine | Invitation</title>
            <meta name="description" content="I'm a specialized in corporate, education and hospitality industry. I work alongside pioneers of the industry to create sought-after products and experiences." />
        </Helmet>
      </HelmetProvider>
      <Header/>
      <Title title='Branding Projects'/>
      <div className='px-2 gap-y-10 md:gap-y-0 md:px-0 py-10 md:py-20 md:gap-x-10 flex flex-col md:flex-row text-center'>
          <div className="grid gap-4 md:grid-cols-4 grid-flow-row px-4 md:px-0">
              {data.brandingProjects.map(
                  (brandingProject, index) =>
                  <div loading='lazy' key={index+1} className='text-center relative overflow-hidden group'>
                      <ModalImage className='duration-500' small={brandingProject.imagePath} large={brandingProject.imagePath} alt={brandingProject.projectName} />
                  </div>
              )}
          </div>
      </div>
      <Reviews/>
      <Footer/>
    </>
  )
}

export default BrandDesigning