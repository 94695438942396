import React from 'react'
import {data} from '../../constants'

const Reviews = () => {
  return (
    <div className='bg-theme-light py-10 md:py-20'>
        <div className="container mx-auto px-4 md:px-20">
            {data.reviews.slice(-3).map(
                (review, index) =>
                <div key={index+1} className='text-center'>
                    <div className='font-manrope font-semibold leading-loose' dangerouslySetInnerHTML={{__html: review.reviewerContent}}  />
                    <p className='font-manrope text-[18px] font-semibold text-grey-900 mb-1'>{review.reviewerName}</p>
                    <p className='font-manrope text-grey-[600]'>{review.reviewerDesignation}</p>
                    <p className='text-sm font-manrope text-grey-[600]'>({review.reviewerCompanyName})</p>
                </div>
            )}
        </div>
    </div>
  )
}

export default Reviews