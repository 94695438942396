import React from 'react'
import { Reviews, Title, Feature} from '../../components';
import {Header, Footer} from '../../containers/';
import {images} from '../../constants'
import { Helmet, HelmetProvider } from 'react-helmet-async';
const Services = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>UI/UX Designer & Developer with wide range of services</title>
            <meta name="description" content="I offer a wide range of services, such as creating stunning websites and building a strong brand to improve the digital appearance of a company." />
        </Helmet>
      </HelmetProvider>
      <Header/>
      <Title title='Services'/>
      <div className='mt-10 md:mt-20 md:mb-10'>
        <Feature
            imageSource={images.websiteDesigning}
            heading={'Creating Stunning Websites that Showcase Your Brand and Drive Results'} 
            description={"Website design is an important aspect of building a strong online presence for businesses and individuals. A well-designed website can help make a positive impression on your visitors, increase user engagement, and drive conversions. Website design is important because it helps leave a positive impression on your visitors, improve user experience, create a strong brand identity, improve search engine rankings, and ensure mobile accessibility. All of these factors contribute to increased website traffic, engagement, and conversions."}
          />
          <Feature
            rowReverse={'flex-row-reverse py-10'}
            imageSource={images.brandDesigning}
            heading={'Building Strong Brands that Stand Out and Resonate with Your Audience'} 
            description={"The brand has a clear value proposition, a unique selling proposition and competitive advantages that set it apart from its competitors. Brands must be able to create a strong emotional connection with their target audience, leading to increased brand loyalty and increased advocacy. Brands must be able to effectively communicate their message and value proposition using language, images, and messages that resonate with the needs, wants, and desires of their audience. Brands must also be able to adapt to the changing needs of audiences and markets over time. The brand must have a clear value proposition, a competitive advantage, and a consistent brand identity to differentiate itself from competitors and have a lasting impact on audiences."}
          />
        </div>
      <Reviews/>
      <Footer/>
    </>
  )
}

export default Services