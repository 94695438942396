import React from 'react'
import { Reviews, Title} from '../../components';
import {Header, Footer} from '../../containers/';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const PrivacyPolicy = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Privacy Policy | Ajeet Singh Baddan</title>
        </Helmet>
      </HelmetProvider>
      <Header/>
      <Title title='Privacy Policy'/>
      <div className='container px-6 gap-y-10 md:gap-y-0 md:px-0 mx-auto py-10 md:py-20 md:gap-x-10 flex flex-col md:flex-row'>
        <div className='flex-1'>

            <h2 className='font-manrope font-semibold text-2xl'>Who we are</h2>
            <p className='font-manrope mb-3'>Our website address is: https://www.ajeetsinghbaddan.com.</p>
            <h3 className='font-manrope font-semibold text-2xl mb-3'>What personal data we collect and why we collect it</h3>
            <h4 className='font-manrope font-semibold text-xl'>Media</h4>
            <p className='font-manrope mb-3'>If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.</p>
            <h4 className='font-manrope font-semibold text-xl'>Cookies</h4>
            <p className='font-manrope mb-3'>If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.</p>
            <p className='font-manrope mb-3'>If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.</p>
            <p className='font-manrope mb-3'>When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.</p>
            <p className='font-manrope mb-3'>If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.</p>
            <h4 className='font-manrope font-semibold text-xl'>Embedded content from other websites</h4>
            <p className='font-manrope mb-3'>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</p>
            <p className='font-manrope mb-3'>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</p>
            <h4 className='font-manrope font-semibold text-xl'>Analytics</h4>
            <p className='font-manrope mb-1 font-semibold'>Who we share your data with</p>
            <p className='font-manrope mb-3'>If you request a password reset, your IP address will be included in the reset email.</p>
            <p className='font-manrope mb-1 font-semibold'>How long we retain your data</p>
            <p className='font-manrope mb-3'>If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.</p>
            <p className='font-manrope mb-3'>For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.</p>
            <p className='font-manrope mb-1 font-semibold'>What rights you have over your data</p>
            <p className='font-manrope mb-3'>If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</p>
            <p className='font-manrope mb-1 font-semibold'>Where we send your data</p>
            <p className='font-manrope mb-3'>Visitor comments may be checked through an automated spam detection service.</p>
        </div>
      </div>
      <Reviews/>
      <Footer/>
    </>
  )
}

export default PrivacyPolicy