import React from 'react'
import {images} from '../../constants/'
import {Navbar} from '../../components/'
import {Link} from 'react-router-dom';
const Header = () => {
  return (
    <>
      <div className='bg-theme-light fixed w-full z-30'>
         <div className='container mx-auto py-3 md:flex justify-between items-center'>
            <div className='px-4 md:px-0'>
                <Link to="/"><img loading='lazy' src={images.logo} alt="Ajeet Singh Baddan" /></Link>
            </div>
            <div>
                <Navbar/>
            </div>
            <div className='hidden md:block'>
                <Link
                  target="_blank"
                  to={'https://docs.google.com/forms/d/e/1FAIpQLSdbJuT4I2sIVhnaDpeA1n6bAo48jprQattK8wl_a3G7qgKOmw/viewform?usp=sf_link'}
                  className='bg-theme-blue rounded text-white px-4 py-3 inline-block duration-500 hover:theme-light'>
                    Start a project
                </Link>
            </div>
         </div>
      </div>
      <div className='f-full h-20 bg-theme-light'></div>
    </>
  )
}

export default Header