import './App.css';
import {Home, About, Services, Projects, Contact, PrivacyPolicy, WebsiteDesigning, BrandDesigning} from './pages/';
import ScrollToTop from './pages/ScrollToTop';
import {Routes, Route} from 'react-router-dom';
function App() {
  return (
    <>
      <ScrollToTop/>
      <Routes>
        <Route index element={<Home/>}></Route>
        <Route path="/about" element={<About/>}></Route>
        <Route path="/services" element={<Services/>}></Route>
        <Route path="/projects" element={<Projects/>}></Route>
        <Route path="/contact" element={<Contact/>}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
        <Route path="/projects/website-designing" element={<WebsiteDesigning/>}></Route>
        <Route path="/projects/brand-designing" element={<BrandDesigning/>}></Route>
      </Routes>
    </>
  );
}

export default App;
