import React from 'react'
import { Reviews, Title} from '../../components';
import {Header, Footer} from '../../containers/';
import {data} from '../../constants'
import { Helmet, HelmetProvider } from 'react-helmet-async';
const WebsiteDesigning = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Website Work including - Static | WordPress | Shopify | Opencart | Laravel</title>
            <meta name="description" content="Work in partnership with clients to build automation solution focused based on growth to work in more comfortable & durable solution See How!" />
        </Helmet>
      </HelmetProvider>
      <Header/>
      <Title title='Web Projects'/>
      <div className='px-2 gap-y-10 md:gap-y-0 md:px-0 py-10 md:py-20 md:gap-x-10 flex flex-col md:flex-row text-center'>
          <div className="grid gap-4 md:grid-cols-4 grid-flow-row px-4 md:px-0">
              {data.websiteProjects.map(
                  (websiteProject, index) =>
                  <div key={index+1} className='text-center relative overflow-hidden group'>
                      <img loading='lazy' src={websiteProject.imagePath} alt={websiteProject.projectName} />
                      <p className='font-manrope font-semibold text-xl text-white p-3 bg-black/70 absolute top-[500px] group-hover:top-0 inset-0 duration-500 flex justify-center items-center'>{websiteProject.projectName}</p>
                  </div>
              )}
          </div>
      </div>
      <Reviews/>
      <Footer/>
    </>
  )
}

export default WebsiteDesigning