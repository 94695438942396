import React from 'react'
import { Hero, Clients, Feature, Projects, Reviews} from '../../components';
import {Header, Footer} from '../../containers/';
import { images } from '../../constants'
import { Helmet, HelmetProvider } from 'react-helmet-async';
const Home = () => {
  return (
    <>
        <HelmetProvider>
          <Helmet>
              <title>Ajeet Singh Baddan - UI Designer &amp; Developer in Delhi</title>
              <meta name="description" content="Mesmerized by creating web + mobile interfaces, brand identities and user experiences for companies who choose interactive & automated website." />
          </Helmet>
        </HelmetProvider>
        <Header/>
        <Hero/>
        <Clients/>
        <Feature
          imageSource={images.creativeSolutions}
          heading={'Creative solutions that captivate your audience and inspire action'} 
          description={"To achieve this, creative solutions can include a range of design elements such as visually appealing graphics, interactive features, bold typography and engaging storytelling. Using these techniques can help your brand stand out from the competition, leaving a lasting impression on your audience and encouraging them to take action. Ultimately, the goal of creating solutions that engage audiences and inspire action is to create positive brand experiences that build trust, loyalty, and long-term customer relationships. By connecting with your audience on an emotional level through effective design, your brand can drive growth, increase engagement, and achieve business goals."}
        />
        <Feature
          rowReverse={'flex-row-reverse py-10'}
          imageSource={images.exceptionalDesign}
          heading={'Brand with exceptional design that leaves a lasting impression.'} 
          description={"The brand's design excellence goes beyond aesthetics and aims to create an unforgettable experience for the public. It's purposeful, helpful and impactful, leaving a lasting impression in the viewer's mind long after the interaction has ended. These brands understand the importance of creating a cohesive and unified brand experience across all touchpoints, including their website, social media, advertising, and physical products. In doing so, it builds trust and credibility with its audience, builds a strong brand image and differentiates itself from its competitors."}
        />
        <Projects
          heading={'Recent Projects'}
        />
        <Reviews/>
        <Footer/>
    </>
  )
}

export default Home