import React from 'react'

const Feature = ({imageSource, heading, description, rowReverse}) => {
  return (
    <div className='container mx-auto px-4 md:px-0'>
      <div className={"md:columns-2 gap-[100px] md:flex items-center " +  (rowReverse)}>
        <div className='flex-1'>
            <img loading='lazy' src={imageSource} alt={heading} className='mb-4 md:mb-0' />
        </div>
        <div className='flex-1'>
          <h3 className='text-xl md:text-[36px] md:leading-[44px] font-manrope font-semibold mb-4'>{heading}</h3>
          <p className='text-sm md:text-[16px] leading-relaxed font-manrope'>{description}</p>
        </div>
      </div>
    </div>
  )
}

export default Feature