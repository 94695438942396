import images from './images';

const hero = [
        {       
                "key": '1',
                "heading1": 'Each service is unique.',
                "heading2": 'It can never be exactly repeated',
                "description": 'The new design process flow was developed to help give a more streamlined approach to our design process. We eliminated the clusters of steps in our five-phases approach and then cultivating five, much more focused, phases within a new process.'
        }

];

const client = [
        {
                "key":"client1",
                "heading":"Our customers appreciate our attention to detail and our commitment to excellence in everything we do.",
                "description":"The ability to bring unique ideas and perspectives to life through imagination."
        }
]

const clientNames =[
        "3D Imaginations",
        "Aarkay India",
        "Acres50realtors",
        "Adventure World",
        "Ajay Anand",
        "Akira Pharmacy",
        "Aqua Lab",
        "Aranya The Kanan",
        "Arnav Holidays",
        "Arsh",
        "Badri Bajaj Foundation",
        "Bajaj Indian Home Stay",
        "Baked Buns",
        "Best Binary",
        "Bit4X",
        "Bhagyashree Singh Raghuwanshiv",
        "CFNS",
        "Corbett Aamod",
        "Crafts Museum",
        "Crest Ultrasonics",
        "Daalcheeni",
        "Digilab",
        "Digital World",
        "Diva Dilusso",
        "Dogra",
        "Ducat Creative",
        "Excel Systems India",
        "Film Bazaar",
        "Friends Comfort",
        "Global Achivers",
        "Harryz",
        "Hercules Health Care",
        "IGBC India",
        "India Unlocked",
        "Kashmir House Boats",
        "Layman Marketing and Research",
        "Lets Go Web",
        "Ruby Singh Archana",
        "Mapping Skills",
        "Mars Communication",
        "Mototech",
        "Namaskar Dunia",
        "Neo Cracy India Travel",
        "Nexion Automation",
        "Pandolin",
        "Pehchan NGO",
        "Perfect Travels",
        "Paras Polycab",
        "Quarkytech",
        "Rahi Foundation",
        "Revastra",
        "Reveira",
        "Royal Bollywood",
        "Sab Chairs",
        "Sex Gender Tech",
        "Shaadi Circle",
        "Shri Ram Computer Education",
        "Skill Folk",
        "Solidaridad",
        "Sphere",
        "Star National",
        "Sunvirose",
        "Swaran Coating",
        "The Democratic Post",
        "Luxury Trains of India",
        "True Renewables Power Solution",
        "Uttam Homes",
        "Yagya Blood bank", 
]

const reviews =[
        {
                reviewerName: 'Rajesh Dubey',
                reviewerDesignation:'Manager',
                reviewerCompanyName:'Aadvik Management and Technologies',
                reviewerContent:"<p>Thank you for your effort to develop and deliver our company, aadvik’s website.</p><p class='mb-4'>Let me place on record that in such a short notice you made an excellent site and much within the short deadline. You were very open to our ideas and without any hesitancy, you cooperated exceptionally well and delivered something for which we are proud of it.</p><p class='mb-5'>We wish you all good luck to you and your company, asb!</p>"
        }
]

const aboutme =[
        {       
                picture:images.ajeetsinghbaddan,
                heading:"Hi, I’m Ajeet Singh Baddan.",
                description:"<p>I create web + mobile interfaces, brand identities, and user experiences and have 11+ years of hands-on experience. I also liked teaching how to design and code for the last 10+ years to those who want to learn. I also, have an interest in CAD Drafting in Autodesk AutoCAD Software and teaching students for the previous decade. When I discover a new technique, I don’t know why but, I want to learn it so that I can share it with others.</p> <p>I have no preference for profitable projects. I prefer to focus on those projects that can make a difference and bring changes and benefits to the professional life of the client. This brings open-mindedness to suggestions and new perspectives. So that I can grow when my client grows, my focus is only on those types of projects which can create challenges so I can learn something new every time. If you are either an agency or freelancer and would like to work with me, feel free to contact me.</p>"
        }
]

const websiteProjects = [
        {       
                projectName:"TheWebDesign-SEO",
                imagePath:images.webthewebdesignseo
        },
        {       
                projectName:"BioTech",
                imagePath:images.webbiotech
        },
        {       
                projectName:"Snow Valley Resorts",
                imagePath:images.websnowvalleyresorts
        },
        {       
                projectName:"Simulacrum AI",
                imagePath:images.websimulacrumai
        },
        {       
                projectName:"Aadvik Management and Technologies",
                imagePath:images.webaadvikinnovators
        },
        {       
                projectName:"SMR Informatics",
                imagePath:images.websmrinfo
        },
        {       
                projectName:"Web Training India",
                imagePath:images.webwebtrainingindia
        },
        {       
                projectName:"Intune Holistic Exercises",
                imagePath:images.webintuneholisticexercises
        },
        {       
                projectName:"Layman Marketing and Research Pvt. Ltd.",
                imagePath:images.weblmrpl
        },
        {       
                projectName:"Tosha International",
                imagePath:images.webtoshainternational
        },
        {       
                projectName:"Shri Ram Singh Hospital & Heart Institute",
                imagePath:images.websrsh
        },
        {       
                projectName:"S A Ethics Biotech PVT. LTD.",
                imagePath:images.websaethicsbio
        },
        {       
                projectName:"California Houseboats",
                imagePath:images.webkashmirhouseboats
        },
        {       
                projectName:"3D Imagitation",
                imagePath:images.web3dimagitation
        },
        {       
                projectName:"The Luxury Trains of India",
                imagePath:images.webtheluxurytrainsofindia
        },
        {       
                projectName:"Perfect Travels & Tours Private Limited",
                imagePath:images.webperfecttravels
        },
        {       
                projectName:"India Unlocked",
                imagePath:images.webindiaunlocked
        },
        {       
                projectName:"Tripxps",
                imagePath:images.webtripxps
        },
        {       
                projectName:"Raj Bhog Food India",
                imagePath:images.webrajbhog
        },
        {       
                projectName:"Organicagri",
                imagePath:images.weborganicagri
        },
        {       
                projectName:"Hercules Health Care",
                imagePath:images.webherculeshealthcare
        },
        {       
                projectName:"SRC Education",
                imagePath:images.websrceducation
        },
        {       
                projectName:"Badri Nath Bajaj Memorial Trust",
                imagePath:images.webbadribajajfoundation
        },
        {       
                projectName:"Prima Cables",
                imagePath:images.webprimacables
        },
        {       
                projectName:"Career Quest",
                imagePath:images.webcqhr
        },
        {       
                projectName:"Taxi Macclesfield",
                imagePath:images.webtaximacclesfield
        },
        {       
                projectName:"Cosmosaic",
                imagePath:images.webcosmosaic
        },
        {       
                projectName:"Ducat India",
                imagePath:images.webducatindia
        },
        {       
                projectName:"Diva Dilusso",
                imagePath:images.webdivadilusso
        },
        {       
                projectName:"Bajaj Indian Home Stay",
                imagePath:images.webbajajindianhomestay
        },
        {       
                projectName:"The Coalition for Food and Nutrition Security",
                imagePath:images.webnutritioncoalition
        },
        {       
                projectName:"Photo Copier",
                imagePath:images.webphotocopierrentaldelhi
        },
        {       
                projectName:"Platinum Transport",
                imagePath:images.webplatinumtransport
        },
        {       
                projectName:"Solidaridad",
                imagePath:images.websolidaridad
        },
        {       
                projectName:"The Democraticpost",
                imagePath:images.webthedemocraticpost
        },
        {       
                projectName:"WiseOnGo",
                imagePath:images.webwiseongo
        },
        {       
                projectName:"Noida Police",
                imagePath:images.webnoidapolice
        },
        {       
                projectName:"Shaadi Circle",
                imagePath:images.webshaadicircle
        }
]

const brandingProjects = [
        {       
                projectName:"Delhi Super Cup",
                imagePath:images.branddelhisupercupflyer
        },
        {       
                projectName:"Paint Horse",
                imagePath:images.brandpainthorse
        },
        {       
                projectName:"Surya Group Business Card",
                imagePath:images.brandsuryagroupbusinesscard
        },
        {       
                projectName:"Meek Gaming",
                imagePath:images.brandmeekgaminglogo
        },
        {       
                projectName:"Web Training India Fold Flyer",
                imagePath:images.brandwebtrainingindiabrochure
        },
        {       
                projectName:"Surya Group Company Profile",
                imagePath:images.brandsuryagroupcompanyprofile
        },
        {       
                projectName:"Iyikatech Company Profile",
                imagePath:images.brandiyikatechcompanyprofile
        },
        {       
                projectName:"Manju Plants",
                imagePath:images.brandmanjuplantslogo
        },
        {       
                projectName:"Goodteam",
                imagePath:images.brandgoodteamlogo
        },
        {       
                projectName:"Project and Process",
                imagePath:images.brandprojectprocesslogo
        },
        {       
                projectName:"Bit 4X",
                imagePath:images.brandbit4xbrochure
        },
        {       
                projectName:"Coupons Forever",
                imagePath:images.brandcouponsforeverlogo
        },
        {       
                projectName:"Zella",
                imagePath:images.brandzellalogo
        },
        {       
                projectName:"NeoCracy India Travel",
                imagePath:images.brandneocracylogo
        },
        {       
                projectName:"BSR Design",
                imagePath:images.brandbsrlogo
        },
        {       
                projectName:"Akira Pharmacy",
                imagePath:images.brandakirapharmacy
        },
        {       
                projectName:"Aslam",
                imagePath:images.brandaslamlogo
        },
        {       
                projectName:"Acres 50 Realtors",
                imagePath:images.brandacres50realtors
        },
        {       
                projectName:"Arnav Holidays",
                imagePath:images.brandarnavholidayslogo
        },
        {       
                projectName:"Best Binary",
                imagePath:images.brandbestbinarylogo
        },
        {       
                projectName:"Contact 4 Support",
                imagePath:images.brandcontact4supportlogo
        },
        {       
                projectName:"Dogra Law Associates",
                imagePath:images.branddogralogo
        },
        {       
                projectName:"Shri Kedarnath",
                imagePath:images.brandshrikedarnathlogo
        },
        {       
                projectName:"Etawah Safari Park",
                imagePath:images.brandetawahsafariparklogo
        },
        {       
                projectName:"HGC",
                imagePath:images.brandhgclogo
        },
        {       
                projectName:"Ifitek",
                imagePath:images.brandifiteklogo
        },
        {       
                projectName:"India Unlocked",
                imagePath:images.brandindiaunlockedlogo
        },
        {       
                projectName:"Jeeto India Jeeto",
                imagePath:images.brandjeetoindiajeetologo
        },
        {       
                projectName:"Krishay",
                imagePath:images.brandkrishaylogo
        },
        {       
                projectName:"Quarky Tech",
                imagePath:images.brandquarkytechlogo
        },
        {       
                projectName:"Sunvirose",
                imagePath:images.brandsunviroselogo
        },
        {       
                projectName:"Sabkuchadda",
                imagePath:images.brandsabkuchaddalogo
        },
        {       
                projectName:"Skill Folk",
                imagePath:images.brandskillfolklogo
        },
        {       
                projectName:"Sphere",
                imagePath:images.brandspherelogo
        },
        {       
                projectName:"SRC Education",
                imagePath:images.brandsrclogo
        },
        {       
                projectName:"Yaha Sub Kuch Milega",
                imagePath:images.brandyahasubkuchmilegalogo
        },
        {       
                projectName:"XS",
                imagePath:images.brandxslogo
        },
        {       
                projectName:"Mars Car Care",
                imagePath:images.brandmarscarcarelogo
        },
        {       
                projectName:"Blue Whale Solution",
                imagePath:images.brandbluewhalesolutionlogo
        },
        {       
                projectName:"Friends Comfort",
                imagePath:images.brandfriendscomfortlogo
        },
        {       
                projectName:"Arjun Defence Academy",
                imagePath:images.brandarjundefenceacademy
        },
        {       
                projectName:"Excel Systems",
                imagePath:images.brandexcelsystemsbusinesscard
        },
        {       
                projectName:"ARSH",
                imagePath:images.brandarshbusinesscard
        },
        {       
                projectName:"Fitness Fuel",
                imagePath:images.brandfitnessfuelbusinesscard
        },
        {       
                projectName:"Mars Car Care",
                imagePath:images.brandmarscarcarebusinesscard
        },
        {       
                projectName:"XS",
                imagePath:images.brandxsbusinesscard
        },
        {       
                projectName:"Hercules Health Care",
                imagePath:images.brandherculeshealthcarebusinesscard
        },
        {       
                projectName:"Yew Engineering",
                imagePath:images.brandyewengineeringbusinesscard
        },
        {       
                projectName:"Shashank Agrawal",
                imagePath:images.brandshashankagrawalbusinesscard
        },
        {       
                projectName:"ARSH",
                imagePath:images.brandarshbillbook
        },
        {       
                projectName:"KSK",
                imagePath:images.brandkskbillbook
        },
        {       
                projectName:"Adventure World India Webinar",
                imagePath:images.brandadventureworld
        },
        {       
                projectName:"GK Label",
                imagePath:images.brandgklabel
        },
        {       
                projectName:"KSK",
                imagePath:images.brandkskletterhead
        },
        {       
                projectName:"Unlike in Nature",
                imagePath:images.brandpass
        },
        {       
                projectName:"Perfect Travels",
                imagePath:images.brandperfecttravelsinfographs
        },
        {       
                projectName:"T-Shirt",
                imagePath:images.brandtshirt
        },
        {       
                projectName:"Yoga Poster",
                imagePath:images.brandyogaposter
        },
        {       
                projectName:"Yoga Poster",
                imagePath:images.brandyogaposterdesignracepcs
        },
        {       
                projectName:"Anniversary Invitation",
                imagePath:images.brandanniversaryinvitation
        },
        {       
                projectName:"Medigyan Magazine",
                imagePath:images.brandmedigyanmagazine
        },
        {       
                projectName:"Arnav Holidays",
                imagePath:images.brandarnavholidays
        },
        {       
                projectName:"Arnav Holidays",
                imagePath:images.brandarnavholidaysproposal
        },
        {       
                projectName:"Arnav Holidays",
                imagePath:images.brandarnavholidaysmalaysia
        }
]

const recentProjectsBrand =[
        {
                projectName:"Delhi Super Cup",
                imagePath:images.redelhisupercupflyerdesign      
        },
        {
                projectName:"Iyikatech",
                imagePath:images.reiyikatech      
        }
]

const recentProjectsWeb =[
        {
                projectName:"Baked Buns",
                imagePath:images.rebakedbuns      
        },
        {
                projectName:"Fur Kitchen",
                imagePath:images.refurkitchen      
        },
        {
                projectName:"Makeup Artistry by RSA",
                imagePath:images.remakeupartistrybyrsa      
        },
        {
                projectName:"Mototech",
                imagePath:images.remototech      
        },
]

export default {hero, client, clientNames, websiteProjects, brandingProjects, reviews, aboutme, recentProjectsBrand, recentProjectsWeb};