import React, { useState } from 'react'
import {images, data} from '../../constants'

const Clients = () => {
    const [clientList, setClientList] = useState(false)
  return (
    <>
        
        <div className="container mx-auto py-10 md:py-20 px-4 md:px-0">

            <div className="md:px-40 text-center">
                <h2 className='font-semibold font-manrope text-grey-900 text-xl md:text-[36px] md:leading-[44px] mb-5'>{data.client.map((clientL, index) => <span key={index+1}>{clientL.heading}</span>)}</h2>
                <p className='text-grey-700 font-manrope md:text-[18px] mb-10'>{data.client.map((clientL, index) => <span key={index+1}>{clientL.description}</span>)}</p>
            </div>

            <div className="columns-2 md:columns-6">
                <img loading='lazy' src={images.client1} alt="India Unlocked" className='grayscale' />
                <img loading='lazy' src={images.client2} alt="True Renewable" className='grayscale' />
                <img loading='lazy' src={images.client3} alt="Baked Buns Unlocked" className='grayscale' />
                <img loading='lazy' src={images.client4} alt="HGC" className='grayscale' />
                <img loading='lazy' src={images.client5} alt="Pehchan" className='grayscale' />
                <img loading='lazy' src={images.client6} alt="Neocracy" className='grayscale' />
            </div>

            <div className="text-center relative mt-10">
                <button onClick={()=>setClientList(!clientList)} className='focus:outline-none font-semibold inline-block text-md text-grey-700 font-manrope after:content-[""] after:w-[4%] after:h-[1px] after:bg-theme-blue after:absolute after:left-[48%] after:duration-500 after:bottom-[-5px] hover:after:w-[10%] hover:after:left-[45%] duration-500'>{clientList ? 'Hide Clients List': 'Show Clients List'}</button>
            </div>

            {clientList ? 
                <div className='duration-500 columns-2 md:columns-4 mt-6'>
                    <ul className='text-grey-700 leading-7 text-sm font-manrope'>
                        {data.clientNames.map((clientName, index) => <li key={index+1}>{clientName}</li>)}
                    </ul>
                </div>
                :
                ''
            }

        </div>
        
    </>
  )
}

export default Clients