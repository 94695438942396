import React from 'react'
import { Reviews, Title} from '../../components';
import {Header, Footer} from '../../containers/';
import {images} from '../../constants'
import { NavLink } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
const Projects = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>List of past projects on which I have worked</title>
            <meta name="description" content="Over the past decade, I have worked on many types of projects, some of which are directly related to reshaping the learning curve and providing insights into my experience." />
        </Helmet>
      </HelmetProvider>
      <Header/>
      <Title title='Projects'/>
      <div className='container px-6 gap-y-10 md:gap-y-0 md:px-0 mx-auto py-10 md:py-20 md:gap-x-10 flex flex-col md:flex-row text-center'>
        <div className='flex-1'>
          <NavLink to='/projects/website-designing'>
            <img loading='lazy' src={images.websiteProjects} alt='Web Projects' />
            <h2 className='manrope font-semibold text-xl theme-blue p-3'>Web Projects</h2>
          </NavLink>
        </div>
        <div className='flex-1'>
          <NavLink to='/projects/brand-designing'>
            <img loading='lazy' src={images.brandingProjects} alt='Branding Projects' />
            <h2 className='manrope font-semibold text-xl theme-blue p-3'>Branding Projects</h2>
          </NavLink>
        </div>
      </div>
      <Reviews/>
      <Footer/>
    </>
  )
}

export default Projects