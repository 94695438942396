import React from 'react'
import { Link } from 'react-router-dom'
import {MdKeyboardArrowRight} from 'react-icons/md'
const Title = ({title}) => {
  return (
    <div className='bg-theme-light'>
         <div className='container mx-auto py-10 md:py-20 md:flex justify-between items-center flex-col text-center'>
            <h1 className='text-5xl font-manrope theme-blue font-semibold mb-5'>{title}</h1>
            <div className='font-semibold font-manrope flex justify-center items-center'><Link to='/' className='hover:text-gray-500 duration-500'>Home</Link><MdKeyboardArrowRight size={18} className='mt-1 mx-2'/><span className='text-gray-500'>{title}</span></div>
        </div>
    </div>
  )
}

export default Title