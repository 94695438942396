import React from 'react'
import { Reviews, Title} from '../../components';
import {Header, Footer} from '../../containers/';
import { Helmet, HelmetProvider } from 'react-helmet-async';
const Contact = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Majestic, contemporary and innovative solutions that surprise you. Contact me!</title>
            <meta name="description" content="Always aim to create an impactful, automated and innovative solution, in order to exceed the expectations of each client." />
        </Helmet>
      </HelmetProvider>
     <Header/>
      <Title title='Contact'/>
      <div className='container mx-auto py-10 md:py-20 text-center'>
           <h2 className='font-manrope font-semibold text-5xl theme-blue mb-4'>Let's Talk</h2> 
           <p className='font-manrope md:text-xl theme-blue mb-4 md:leading-loose'>If you have any questions, don’t be shy!<br/>Contact through below communication mode with your question(s) as efficiently as possible.</p>
           <p className='font-manrope text-[19px] md:text-2xl theme-blue font-semibold md:leading-loose'>ajeetsinghbaddan[at]gmail[dot]com<br/>ajeetsinghbaddan[Skype]<br/>+91 9871-9781-67</p>
      </div>
      <Reviews/>
      <Footer/>
    </>
  )
}

export default Contact