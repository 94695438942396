import React from 'react'
import { Reviews, Title} from '../../components';
import {Header, Footer} from '../../containers/';
import { data } from '../../constants'
import { Helmet, HelmetProvider } from 'react-helmet-async';
const About = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Ajeet Singh Baddan | Freelancer Web Designer, UX/UX Designing & Development</title>
            <meta name="description" content="Meet the Designer who is behind the vision. Ajeet Singh Baddan, UI/UX Designer, who working for the last 11 years to create automated solutions for clients." />
        </Helmet>
      </HelmetProvider>
      <Header/>
      <Title title='About'/>
      <div className='container mx-auto py-20'>
          <div className='flex justify-between items-center flex-col px-6 md:px-0 md:flex-row'>
            <div className='flex-1'>
              {data.aboutme.map((about, index) => <img loading='lazy' key={index+1} src={about.picture} alt='Ajeet Singh Baddan' className='mb-8 md:mb-0'/>)}
            </div>
            <div className='flex-1'>
              {data.aboutme.map((about, index) =>
              <div key={index+1}>
                <h2 className='text-2xl md:text-5xl font-manrope theme-blue font-semibold mb-5'>{about.heading}</h2>
                <div className='text-sm md:text-[16px] leading-relaxed font-manrope mb-5 flex flex-col gap-y-5' dangerouslySetInnerHTML={{__html: about.description}}  />
              </div>
              )}
          </div>
        </div>
      </div>
      <Reviews/>
      <Footer/>
    </>
  )
}

export default About