import React from 'react'
import {data} from '../../constants'

const Projects = ({heading}) => {
  return (
    <div className='mt-10 mb-10 md:mb-20'>

        <h4 className='text-center text-xl md:text-[36px] md:leading-[44px] font-manrope font-semibold mb-10'>{heading}</h4>    

        <div className="flex flex-row">
            {data.recentProjectsBrand.map(
                (recentProjectBrand, index) =>
                <div key={index+1} className='text-center relative overflow-hidden group'>
                    <img loading='lazy' src={recentProjectBrand.imagePath} alt={recentProjectBrand.projectName} />
                    <p className='font-manrope font-semibold text-xl text-white p-3 bg-black/70 absolute top-[500px] group-hover:top-0 inset-0 duration-500 flex justify-center items-center'>{recentProjectBrand.projectName}</p>
                </div>
            )}
        </div>    

        <div className="flex flex-row">
            {data.recentProjectsWeb.map(
                (recentProjectWeb, index) =>
                <div key={index+1} className='text-center relative overflow-hidden group'>
                    <img loading='lazy' src={recentProjectWeb.imagePath} alt={recentProjectWeb.projectName} />
                    <p className='font-manrope font-semibold text-xl text-white p-3 bg-black/70 absolute top-[500px] group-hover:top-0 inset-0 duration-500 flex justify-center items-center'>{recentProjectWeb.projectName}</p>
                </div>
            )}
        </div>

    </div>
  )
}

export default Projects