import React from 'react'
import { Link } from 'react-router-dom'
import {BsBehance} from 'react-icons/bs'
const Footer = () => {
  return (
    <div className='container mx-auto py-5'>
      
      <div className="md:flex md:columns-3 items-end px-4 md:px-0">
        <div className='w-full text-center md:text-start'>
          <h6 className='font-manrope font-semibold text-grey-900 mb-2'>Socialize</h6>
          <Link className='text-center inline-block' to="https://www.behance.net/ajeetsinghbaddan" target='_blank'><BsBehance/></Link>
        </div>
        <div className='w-full text-center py-4 md:py-0'>
          <p className='font-manrope text-sm text-grey-600'>&copy; 2023 Ajeet Singh Baddan. <Link to="/privacy-policy">Privacy Policy</Link></p>
        </div>
        <div className='w-full text-center md:text-end'>
          <h6 className='font-manrope font-semibold text-grey-900 mb-2'>Let's talk about something</h6>
          <p className='font-manrope text-sm text-grey-600'><Link to="mailto:ajeetsinghbaddan@gmail.com" target='_blank'>ajeetsinghbaddan[at]gmail[dot]com</Link></p>
        </div>
      </div>

    </div>
  )
}

export default Footer