import React from 'react'
import {images, data} from '../../constants/'

const Hero = () => {

  return (
    <>
        <div className='bg-theme-light py-10 md:py-20'>
            <div className='container mx-auto text-center flex justify-center'>
                <div className='md:w-[60%] px-4'>
                    <h1 className='font-manrope font-semibold leading-tight text-2xl md:text-[48px]'>
                      {data.hero.map((he, index) => <span key={index+1}>{he.heading1}</span>)}
                      <span className='md:block'> </span>
                      {data.hero.map((he, index) => <span key={index+1}>{he.heading2}</span>)}
                    </h1>
                    <p className='mt-5 font-manrope'>{data.hero.map((he, index) => <span key={index+1}>{he.description}</span>)}</p>
                    <img loading='lazy' src={images.process} alt="Work Process" className='mx-auto mt-10 md:mt-20' />
                </div>
            </div>
        </div>
    </>
  )
}

export default Hero