import React, { useState } from 'react'
import {NavLink} from 'react-router-dom';
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'
const pages = [
    {
        menuName: "Home",
        menuLink:"/"
    },
    {
        menuName:"About",
        menuLink:"/about"
    },
    {
        menuName:"Services",
        menuLink:"/services"
    },
    {
        menuName:"Projects",
        menuLink:"/projects"
    },
    {
        menuName:"Contact",
        menuLink:"/contact"
    }
]

const Navbar = () => {
const [nav, setNav] = useState(true)
  return (
    <>  
        <div className={nav ? 'md:flex items-center fixed md:static bg-theme-light top-[55px] -left-[500px] px-5 duration-500' : 'md:flex items-center fixed md:static inset-0 md:inset-auto bg-theme-light top-[55px] left-0 px-5 duration-500'}>

            <ul className='md:flex justify-center'>  
                {pages.map((page) => (
                    <li key={page.menuLink} className='md:mx-4 font-manrope text-base font-semibold my-5 md:my-0'>
                        <NavLink to={page.menuLink} className={({ isActive }) => isActive ? 'theme-blue duration-500 border-b-2 duration-500 border-dotted hover:text-gray-900 border-stone-900' : 'text-gray-900 duration-500 border-b-2 duration-500 border-transparent border-dotted hover:border-stone-900 hover:text-gray-900' }>{page.menuName}</NavLink>
                    </li>
                ))}
            </ul>

            <NavLink
                target="_blank"
                to={'https://docs.google.com/forms/d/e/1FAIpQLSdbJuT4I2sIVhnaDpeA1n6bAo48jprQattK8wl_a3G7qgKOmw/viewform?usp=sf_link'}
                className='bg-theme-blue rounded text-white px-4 py-3 inline-block duration-500 hover:theme-light md:hidden'>
                Start a project
            </NavLink>
            
        </div>

        <div className='md:hidden'>
            {nav ? <AiOutlineMenu size={25} className='fixed top-[17px] right-[15px] duration-500 cursor-pointer' onClick={() => setNav(!nav)}/> : <AiOutlineClose size={25} className='fixed top-[17px] right-[15px] duration-500 cursor-pointer' onClick={() => setNav(!nav)}/>}
        </div>
  </>
  )
}

export default Navbar