
import logo from '../assets/logo.svg';
import process from '../assets/process.svg';
import client1 from '../assets/client1.png';
import client2 from '../assets/client2.png';
import client3 from '../assets/client3.png';
import client4 from '../assets/client4.png';
import client5 from '../assets/client5.png';
import client6 from '../assets/client6.png';
import creativeSolutions from '../assets/creative-solutions.jpg';
import exceptionalDesign from '../assets/exceptional-design.jpg';
import ajeetsinghbaddan from '../assets/ajeetsinghbaddan.jpg';
import websiteDesigning from '../assets/website-desinging.jpg';
import brandDesigning from '../assets/brand-designing.jpg';
import brandingProjects from '../assets/branding-projects.jpg';
import websiteProjects from '../assets/website-projects.jpg';

// For Website Projects
import webthewebdesignseo from '../assets/website-work/thewebdesignseo.jpg';
import webbiotech from '../assets/website-work/biotech.jpg';
import websnowvalleyresorts from '../assets/website-work/snowvalleyresorts.jpg';
import websimulacrumai from '../assets/website-work/simulacrumai.jpg';
import webaadvikinnovators from '../assets/website-work/aadvikinnovators.jpg';
import websmrinfo from '../assets/website-work/smrinfo.jpg';
import webwebtrainingindia from '../assets/website-work/webtrainingindia.jpg';
import webintuneholisticexercises from '../assets/website-work/intuneholisticexercises.jpg';
import weblmrpl from '../assets/website-work/lmrpl.jpg';
import webtoshainternational from '../assets/website-work/toshainternational.jpg';
import websrsh from '../assets/website-work/srsh.jpg';
import websaethicsbio from '../assets/website-work/saethicsbio.jpg';
import webkashmirhouseboats from '../assets/website-work/kashmirhouseboats.jpg';
import web3dimagitation from '../assets/website-work/3d-imagitation.jpg';
import webtheluxurytrainsofindia from '../assets/website-work/theluxurytrainsofindia.jpg';
import webperfecttravels from '../assets/website-work/perfecttravels.jpg';
import webindiaunlocked from '../assets/website-work/indiaunlocked.jpg';
import webtripxps from '../assets/website-work/tripxps.jpg';
import webrajbhog from '../assets/website-work/rajbhog.jpg';
import weborganicagri from '../assets/website-work/organicagri.jpg';
import webherculeshealthcare from '../assets/website-work/herculeshealthcare.jpg';
import websrceducation from '../assets/website-work/srceducation.jpg';
import webbadribajajfoundation from '../assets/website-work/badribajajfoundation.jpg';
import webprimacables from '../assets/website-work/primacables.jpg';
import webcqhr from '../assets/website-work/cqhr.jpg';
import webtaximacclesfield from '../assets/website-work/taximacclesfield.jpg';
import webcosmosaic from '../assets/website-work/cosmosaic.jpg';
import webducatindia from '../assets/website-work/ducatindia.jpg';
import webdivadilusso from '../assets/website-work/divadilusso.jpg';
import webbajajindianhomestay from '../assets/website-work/bajajindianhomestay.jpg';
import webnutritioncoalition from '../assets/website-work/nutritioncoalition.jpg';
import webphotocopierrentaldelhi from '../assets/website-work/photocopierrentaldelhi.jpg';
import webplatinumtransport from '../assets/website-work/platinumtransport.jpg';
import websolidaridad from '../assets/website-work/solidaridad.jpg';
import webthedemocraticpost from '../assets/website-work/thedemocraticpost.jpg';
import webwiseongo from '../assets/website-work/wiseongo.jpg';
import webnoidapolice from '../assets/website-work/noidapolice.jpg';
import webshaadicircle from '../assets/website-work/shaadicircle.jpg';

// For Branding Projects
import brandacres50realtors from '../assets/branding-work/acres-50-realtors-logo-mockup.jpg'
import brandadventureworld from '../assets/branding-work/adventure-world-webinar-design.jpg'
import brandakirapharmacy from '../assets/branding-work/akira-pharmacy-logo-design.jpg'
import brandanniversaryinvitation from '../assets/branding-work/anniversary-invitation.jpg'
import brandarjundefenceacademy from '../assets/branding-work/arjun-defence-academy-business-card.jpg'
import brandarnavholidays from '../assets/branding-work/arnav-holidays-flyer.jpg'
import brandarnavholidaysmalaysia from '../assets/branding-work/arnav-holidays-flyer-malaysia.jpg'
import brandarnavholidayslogo from '../assets/branding-work/arnav-holidays-logo-design.jpg'
import brandarnavholidaysproposal from '../assets/branding-work/arnav-holidays-proposal-design.jpg'
import brandarshbillbook from '../assets/branding-work/arsh-billbook-design.jpg'
import brandarshbusinesscard from '../assets/branding-work/arsh-business-card-design.jpg'
import brandaslamlogo from '../assets/branding-work/aslam-logo-design.jpg'
import brandbestbinarylogo from '../assets/branding-work/best-binary-logo-design.jpg'
import brandbit4xbrochure from '../assets/branding-work/bit4x-brochure-design.jpg'
import brandbluewhalesolutionlogo from '../assets/branding-work/blue-whale-solution-logo-design.jpg'
import brandbsrlogo from '../assets/branding-work/bsr-logo-design.jpg'
import brandcontact4supportlogo from '../assets/branding-work/contact4support-logo-design.jpg'
import brandcouponsforeverlogo from '../assets/branding-work/couponsforever-logo-design.jpg'
import branddelhisupercupflyer from '../assets/branding-work/delhi-super-cup-flyer-design.jpg'
import branddogralogo from '../assets/branding-work/dogra-logo-design.jpg'
import brandetawahsafariparklogo from '../assets/branding-work/etawah-safari-park-logo-design.jpg'
import brandexcelsystemsbusinesscard from '../assets/branding-work/excel-systems-business-card-design.jpg'
import brandfitnessfuelbusinesscard from '../assets/branding-work/fitness-fuel-business-card-design.jpg'
import brandfriendscomfortlogo from '../assets/branding-work/friends-comfort-logo-design.jpg'
import brandgklabel from '../assets/branding-work/gk-label-design.jpg'
import brandgoodteamlogo from '../assets/branding-work/goodteam-logo-design.jpg'
import brandherculeshealthcarebusinesscard from '../assets/branding-work/herculeshealthcare-business-card-design.jpg'
import brandhgclogo from '../assets/branding-work/hgc-logo-design.jpg'
import brandifiteklogo from '../assets/branding-work/ifitek-logo-design.jpg'
import brandindiaunlockedlogo from '../assets/branding-work/india-unlocked-logo-design.jpg'
import brandiyikatechcompanyprofile from '../assets/branding-work/iyikatech-company-profile-design.jpg'
import brandjeetoindiajeetologo from '../assets/branding-work/jeeto-india-jeeto-logo-design.jpg'
import brandkrishaylogo from '../assets/branding-work/krishay-logo-design.jpg'
import brandkskbillbook from '../assets/branding-work/ksk-billbook-design.jpg'
import brandkskletterhead from '../assets/branding-work/ksk-letterhead-design.jpg'
import brandmanjuplantslogo from '../assets/branding-work/manju-plants-logo-design.jpg'
import brandmarscarcarebusinesscard from '../assets/branding-work/marscarcare-business-card-design.jpg'
import brandmarscarcarelogo from '../assets/branding-work/marscarcare-logo-design.jpg'
import brandmedigyanmagazine from '../assets/branding-work/medigyan-magazine-design.jpg'
import brandmeekgaminglogo from '../assets/branding-work/meek-gaming-logo-design.jpg'
import brandneocracylogo from '../assets/branding-work/neocracy-logo-design.jpg'
import brandpainthorse from '../assets/branding-work/paint-horse-design.jpg'
import brandpass from '../assets/branding-work/pass-design.jpg'
import brandperfecttravelsinfographs from '../assets/branding-work/perfect-travels-infographs-design.jpg'
import brandprojectprocesslogo from '../assets/branding-work/project-process-logo-design.jpg'
import brandquarkytechlogo from '../assets/branding-work/quarkytech-logo-design.jpg'
import brandsabkuchaddalogo from '../assets/branding-work/sabkuchadda-logo-design.jpg'
import brandshashankagrawalbusinesscard from '../assets/branding-work/shashank-agrawal-business-card-design.jpg'
import brandshrikedarnathlogo from '../assets/branding-work/shri-kedarnath-logo-design.jpg'
import brandskillfolklogo from '../assets/branding-work/skill-folk-logo-design.jpg'
import brandspherelogo from '../assets/branding-work/sphere-logo-design.jpg'
import brandsrclogo from '../assets/branding-work/src-logo-design.jpg'
import brandsunviroselogo from '../assets/branding-work/sunvirose-logo-mockup.jpg'
import brandsuryagroupbusinesscard from '../assets/branding-work/suryagroup-business-card-design.jpg'
import brandsuryagroupcompanyprofile from '../assets/branding-work/suryagroup-company-profile-design.jpg'
import brandtshirt from '../assets/branding-work/t-shirt-design.jpg'
import brandwebtrainingindiabrochure from '../assets/branding-work/webtrainingindia-brochure-design.jpg'
import brandxsbusinesscard from '../assets/branding-work/xs-business-card-design.jpg'
import brandxslogo from '../assets/branding-work/xs-logo-design.jpg'
import brandyahasubkuchmilegalogo from '../assets/branding-work/yaha-sub-kuch-milega-logo.jpg'
import brandyewengineeringbusinesscard from '../assets/branding-work/yew-engineering-business-card-design.jpg'
import brandyogaposter from '../assets/branding-work/yoga-poster-design.jpg'
import brandyogaposterdesignracepcs from '../assets/branding-work/yoga-poster-design-race-pcs.jpg'
import brandzellalogo from '../assets/branding-work/zella-logo-design.jpg'

// Recent Projects
import redelhisupercupflyerdesign from '../assets/recent-work/delhisupercupflyerdesign.jpg'
import reiyikatech from '../assets/recent-work/iyikatech.jpg'
import rebakedbuns from '../assets/recent-work/bakedbuns.jpg'
import refurkitchen from '../assets/recent-work/furkitchen.jpg'
import remakeupartistrybyrsa from '../assets/recent-work/makeupartistrybyrsa.jpg'
import remototech from '../assets/recent-work/mototech.jpg'

export default {
    logo,
    process,
    client1, client2, client3, client4, client5, client6,
    creativeSolutions, exceptionalDesign,
    ajeetsinghbaddan,
    websiteDesigning,
    brandDesigning,
    brandingProjects,
    websiteProjects,
    webthewebdesignseo, webbiotech, websnowvalleyresorts, webaadvikinnovators, websimulacrumai, websmrinfo, webwebtrainingindia, webintuneholisticexercises, weblmrpl, webtoshainternational, websrsh, websaethicsbio, webkashmirhouseboats, web3dimagitation, webtheluxurytrainsofindia, webperfecttravels, webindiaunlocked, webtripxps, webrajbhog, weborganicagri, webherculeshealthcare, websrceducation, webbadribajajfoundation, webprimacables, webcqhr, webtaximacclesfield, webcosmosaic, webducatindia, webdivadilusso, webbajajindianhomestay, webnutritioncoalition, webphotocopierrentaldelhi, webplatinumtransport, websolidaridad, webthedemocraticpost, webwiseongo, webnoidapolice, webshaadicircle,

    brandacres50realtors, brandadventureworld, brandakirapharmacy, brandanniversaryinvitation, brandarjundefenceacademy, brandarnavholidays, brandarnavholidaysmalaysia, brandarnavholidayslogo, brandarnavholidaysproposal, brandarshbillbook, brandarshbusinesscard, brandaslamlogo, brandbestbinarylogo, brandbit4xbrochure, brandbluewhalesolutionlogo, brandbsrlogo, brandcontact4supportlogo, brandcouponsforeverlogo, branddelhisupercupflyer, branddogralogo, brandetawahsafariparklogo, brandexcelsystemsbusinesscard, brandfitnessfuelbusinesscard, brandfriendscomfortlogo, brandgklabel, brandgoodteamlogo, brandherculeshealthcarebusinesscard, brandhgclogo, brandifiteklogo, brandindiaunlockedlogo, brandiyikatechcompanyprofile, brandjeetoindiajeetologo, brandkrishaylogo, brandkskbillbook, brandkskletterhead, brandmanjuplantslogo, brandmarscarcarebusinesscard, brandmarscarcarelogo, brandmedigyanmagazine, brandmeekgaminglogo, brandneocracylogo, brandpainthorse, brandpass, brandperfecttravelsinfographs, brandprojectprocesslogo, brandquarkytechlogo, brandsabkuchaddalogo, brandshashankagrawalbusinesscard, brandshrikedarnathlogo, brandskillfolklogo, brandspherelogo, brandsrclogo, brandsunviroselogo, brandsuryagroupbusinesscard, brandsuryagroupcompanyprofile, brandtshirt, brandwebtrainingindiabrochure, brandxsbusinesscard, brandxslogo, brandyahasubkuchmilegalogo, brandyewengineeringbusinesscard, brandyogaposter, brandyogaposterdesignracepcs, brandzellalogo,

    redelhisupercupflyerdesign, reiyikatech, rebakedbuns, refurkitchen, remakeupartistrybyrsa, remototech
};